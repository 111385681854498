<template>
  <div>
    <b-alert
      show
      variant="danger"
      :style="`position: fixed; left: 50px; right: 50px; bottom: ${
        !isOnline && isAuthenticated ? '10' : '-100'
      }px; z-index: 99; text-align: center;`"
    >
      <slot />
    </b-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "VOffline",
  props: {},
  data: () => ({
    isOnline: true,
  }),
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  mounted() {
    this.$eventBus.on("ws-state", (state) => {
      this.$log.warn("[BASE] State change: " + state);
      this.$data.isOnline = state;
    });
  },
  beforeDestroy() {
    this.$eventBus.off("ws-state");
  },
};
</script>
