<template>
  <!-- begin:: Footer -->
  <div class="
      kt-footer
      kt-grid__item
      kt-grid kt-grid--desktop kt-grid--ver-desktop
    " id="kt_footer">
    <div class="kt-container" v-bind:class="{ 'kt-container--fluid': isFluid() }"
      style="justify-content: flex-end;">
      <div class="kt-footer__copyright"
        :style="`opacity: 0.3; color: var(--${ configLoaded && config.brand.self.skin === 'light' ? 'dark' : 'light' })`">
        2016-{{ new Date().getFullYear() }} &nbsp;&copy;&nbsp; BetterPortal by
        BetterCorp (PTY) Ltd - All Rights Reserved
        <span v-if="configLoaded">-
          BetterPortal for {{ config.tenant.name }}</span> -v{{ $appversion }}
      </div>
      <!-- <div class="kt-footer__menu">
        <router-link to="/System/SStatus">
          System Status
        </router-link>&nbsp;-&nbsp;BetterPortal for {{ config.tenant.name }}
        <a
          v-for="websiteName in Object.keys(config.tenant.company.links)"
          v-bind:key="websiteName"
          :href="config.tenant.company.links[websiteName]"
          target="_blank"
          rel="nofollow"
          class="kt-footer__menu-link kt-link"
          >&nbsp;-&nbsp;{{ websiteName.trim() }}</a
        >
      </div> -->
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  methods: {
    /**
     * Check if footer container is fluid
     * @returns {boolean}
     */
    isFluid() {
      return this.layoutConfig("footer.self.width") === "fluid";
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "config", "configLoaded"])
  }
};
</script>

<style scoped>
.kt-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -1;
}
</style>