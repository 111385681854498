import axios from 'axios';

export class Gravatar {
  static getHashedUser(currentUser) {
    //console.log(currentUser)
    return window.md5(currentUser.email);
  }
  static getProfile(currentUser) {
    const self = this;
    return new Promise((resolve, reject) => {
      if (currentUser === undefined || currentUser === null) return reject();
      axios.get(`https://www.gravatar.com/${self.getHashedUser(currentUser)}.json`).then(x => {
        resolve(x.data);
      }).catch(reject);
    })
  }
  static getNewProfile(email) {
    const self = this;
    return new Promise((resolve, reject) => {
      axios.get(`https://www.gravatar.com/${self.getHashedUser(email)}.json`).then(x => {
        resolve(x.data);
      }).catch(reject);
    })
  }
  static getProfileImageUrl(currentUser, defaultImage) {
    if (currentUser === undefined || currentUser === null) return defaultImage;
    return `https://www.gravatar.com/avatar/${this.getHashedUser(currentUser)}?d=${encodeURIComponent( defaultImage )}`
  }
}