<template>
  <div v-show="
      !$tools.isNullOrUndefined($route.meta) &&
      !$tools.isNullOrUndefined($route.meta.serviceSelector) &&
      !$tools.isNullOrUndefined(
        $route.meta.serviceSelector.services
      ) &&
      services !== null &&
      servicesList.length > 1
    ">
    <template v-if="
      !$tools.isNullOrUndefined($route.meta) &&
      !$tools.isNullOrUndefined($route.meta.serviceSelector) &&
      !$tools.isNullOrUndefined(
        $route.meta.serviceSelector.services
      ) &&
      services !== null &&
      servicesList.length > 1
    ">
      <!-- <v-autocomplete
              :dark="false"
              :light="true"
              :theme="theme()"
              :items="servicesList"
              item-text="text"
              item-value="value"
              v-model="selectedServiceId"
              xplaceholder="Start typing to Search"
              :label="
                $route.meta.serviceSelector.label || 'Select related service'
              "
              @change="selectService(selectedServiceId)"
            ></v-autocomplete> -->
      <b-form-select style="margin: 7px; border-radius: 50px;" v-model="selectedServiceId" class="mb-3"
        @change="selectService(selectedServiceId)">
        <option v-for="item in servicesList" v-bind:key="`${ item.text }:${ item.value }`" :value="item.value">
          {{ item.text }}
        </option>
      </b-form-select>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'ServicesSelector',
  data() {
    return {
      serviceMappings: null,
      serviceMappingsRef: null,
      services: null,
      selectedServiceId: null
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUser", "clientId", "theme"]),

    servicesList() {
      /*const serviceMapping = this.layoutConfig().serviceMapping;
      if (serviceMapping === undefined || serviceMapping === null) return [];
      console.log(this.layoutConfig())
      console.log(serviceMapping)
      console.log(this.services)
      console.log(this.$route.meta)*/
      if (this.services === null) return [];
      if (
        this.$tools.isNullOrUndefined(this.$route.meta) ||
        this.$tools.isNullOrUndefined(this.$route.meta.serviceSelector)
      )
        return [];
      let listToReturn = [];

      //console.log('Services', this.services, this.$route.meta.serviceSelector);
      for (let service of this.services) {
        let mappedServiceRef = this.getMappedService(service.serviceId);
        //console.log('Services', mappedServiceRef, this.$route.meta.serviceSelector);
        if (mappedServiceRef == null) continue;

        for (let serviceSelector of this.$route.meta.serviceSelector.services) {
          let okay = true;
          /*if (!this.$tools.isNullOrUndefined(serviceSelector.status)) {
            if (serviceSelector.status.indexOf(service.status) < 0)
              okay = false;
          }*/
          if (serviceSelector.indexOf(mappedServiceRef) < 0) okay = false;
          /*if (!this.$tools.isNullOrUndefined(serviceSelector.serviceId)) {
            if (serviceSelector.serviceId.indexOf(service.serviceId) < 0)
              okay = false;
          }*/
          /*if (okay) {
            for (let attr of service.attributes) {
              if (attr.key === "serviceName" && attr.value !== null) {
                listToReturn.push({
                  text: attr.value,
                  value: service.id,
                });
                okay = false;
                break;
              }
            }
          }*/
          if (okay) {
            let serviceName = !this.$tools.isNullOrUndefined(
              this.$route.meta.serviceSelector.nameFunc
            )
              ? this.$route.meta.serviceSelector.nameFunc(this, service)
              : service.name;
            //if (!this.$tools.isNullOrUndefined(serviceName)) {
            listToReturn.push({
              text: serviceName || service.name,
              value: service.id
            });
            //}
          }
        }
      }

      return listToReturn;
    }
  },
  watch: {
    $route: {
      deep: true,
      handler: "handleRouteChange"
    },
    servicesList() {
      this.$emit('hasServices', this.servicesList.length > 1);
    }
  },
  mounted() {
    let self = this;
    self.$data.serviceMappingsRef = this.layoutConfig().serviceMapping;
    self.$data.serviceMappings = Object.keys(this.serviceMappingsRef);
    self.$eventBus.on(`cache-crm:services`, () => self.updateServices());
    self.$eventBus.on(`ws-crm-event`, () =>
      self.updateServices(undefined, true)
    );
    self.updateServices(self.handleRouteChange);
  },
  methods: {
    getMappedService(serviceId) {
      for (let serviceKey of this.serviceMappings) {
        if (`${ serviceId }` == `${ this.serviceMappingsRef[serviceKey] }`)
          return serviceKey;
      }
      return null;
    },
    selectService(serviceId) {
      //console.log('selectService', serviceId, this.$route, this.$route.meta.serviceSelector.storageKey);
      //if (this.selectedServiceId == serviceId) return;
      this.$data.selectedServiceId = serviceId || null;
      window.sessionStorage.setItem(
        this.$route.meta.serviceSelector.storageKey || "serviceStorekey",
        serviceId
      );
      if (!this.$tools.isNullOrUndefined(this.$route.meta.routeChange)) {
        let newPath = this.$route.meta.routeChange(this, serviceId);
        if (!this.$tools.isNullOrUndefined(newPath)) {
          if (
            this.$route.path === newPath ||
            this.$route.path.indexOf(newPath) === 0
          )
            return;
          const self = this;
          //this.$nextTick(() => {
          self.$eventBus.emit("switch-service-selected", serviceId);
          //});
          this.$router.push(newPath);
        }
      }
      //console.log('go to: ' + this.$route.meta.routeChange(this, serviceId))
    },
    handleRouteChange() {
      let self = this;
      //console.log('LO TO:', this.$route, self.servicesList);
      this.$data.selectedServiceId = null;
      if (self.servicesList.length > 0) {
        let currentSetItem = window.sessionStorage.getItem(
          self.$route.meta.serviceSelector.storageKey || "serviceStorekey"
        );
        let serviceToGoTo = null;
        if (!self.$tools.isNullOrUndefined(currentSetItem)) {
          for (let serviceItem of self.servicesList) {
            if (`${ serviceItem.value }` == `${ currentSetItem }`) {
              serviceToGoTo = serviceItem.value;
              break;
            }
          }
        }
        //console.log('serviceToGoTo', serviceToGoTo);
        if (
          self.$tools.isNullOrUndefined(serviceToGoTo) &&
          self.$route.meta.serviceSelector.autoSelectFirstService === true
        ) {
          serviceToGoTo = self.servicesList[0].value;
        }
        if (!self.$tools.isNullOrUndefined(serviceToGoTo)) {
          self.selectService(serviceToGoTo);
        } else {
          self.$router.push(
            "/System/NoService?x=1&serviceName=" + self.$route.params.serviceName
          );
        }
      } else {
        self.$data.selectedServiceId = null;
        if (
          !this.$tools.isNullOrUndefined(this.$route.meta) &&
          !this.$tools.isNullOrUndefined(this.$route.meta.serviceSelector)
        ) {
          self.$router.push(
            "/System/NoService?x=2&serviceName=" + self.$route.meta.name
          );
        }
      }
    },
    updateServices(reso = () => { }) {
      let self = this;
      self.$api
        .getWithCache(
          self,
          "crm",
          `${ self.$store.getters.clientId }/service-keys`,
          "crm:service-keys",
          false
        )
        .then(data => {
          self.$data.services = data;
          reso();
        })
        .catch(response => {
          self.$log.error(response);
        });
    }
  }
};
</script>
