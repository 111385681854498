<template>
  <span v-if="menu.special !== undefined" :class="{ 'kt-menu__toggle': menu.submenu }"
  @click="$refs.specialComponent.click()"
    class="kt-menu__link menu-link-active">
    <i v-if="menu.icon" class="kt-menu__link-icon" :class="menu.icon"></i>
    <i v-if="!menu.icon && !menu.root" class="kt-menu__link-bullet" :class="bullet()"><span></span></i>
    <span class="kt-menu__link-text">{{
        menu.translate ? $t(menu.translate) : menu.title
    }}</span>
    <div v-if="menu.comingsoon === true">
      <v-chip :x-small="true" style="background: var(--info); color: #FFFFFF;margin-right: 5px;">
        EA
      </v-chip>
    </div>
    <i v-if="menu.submenu" class="kt-menu__ver-arrow la la-angle-right"></i>
    <component :is="`Special${menu.special}`" ref="specialComponent" :menu="menu"></component>
  </span>
  <component v-else :is="
    menu.comingsoon === true || link() == null ? 'span' : directionalLinkOutwards ? 'a' : 'router-link'
  " :href="link() == null ? undefined : link().path" :to="link()" :class="{ 'kt-menu__toggle': menu.submenu }"
    class="kt-menu__link menu-link-active">
    <i v-if="menu.icon" class="kt-menu__link-icon" :class="menu.icon"></i>
    <i v-if="!menu.icon && !menu.root" class="kt-menu__link-bullet" :class="bullet()"><span></span></i>
    <span class="kt-menu__link-text">{{
        menu.translate ? $t(menu.translate) : menu.title
    }}</span>
    <div v-if="menu.comingsoon === true">
      <v-chip :x-small="true" style="background: var(--info); color: #FFFFFF;margin-right: 5px;">
        EA
      </v-chip>
    </div>
    <i v-if="menu.submenu" class="kt-menu__ver-arrow la la-angle-right"></i>
  </component>
</template>

<script>
import Special1 from './Special/1.vue';

export default {
  name: "KTMenuItemText",
  components: { Special1 },
  props: {
    menu: Object,
    parentMenu: Object,
  },
  computed: {
    meta() {
      return this.menu.meta || null;
    },
    directionalLinkOutwards() {
      if (this.meta === undefined || this.meta === null) return false;
      if ((this.meta.type || null) === null) return false;
      return (this.meta.type || null) == "external";
    },
  },
  methods: {
    /**
     * Get bullet class from config
     * @returns {string} class name
     */
    bullet: function() {
      // get the default bullet
      if (!this.menu.bullet) {
        this.menu.bullet = "dot";
      }
      // bullet class name
      return `kt-menu__link-bullet--${ this.menu.bullet }`;
    },
    /**
     * Get current menu link path
     * @returns {{}|{path: string}}
     */
    link: function() {
      // check if no link in this menu item
      if (!this.menu.page) {
        return null;
      }
      // pass the link prepend with slash
      return { path: `/${ this.menu.page }` };
    },
  },
};
</script>

<style scoped>
.menu-link-active {
  margin-left: 5px !important;
  margin-right: 10px !important;
  border-radius: 15px !important;
  margin-bottom: 1px !important;
  margin-top: 1px !important;
}
</style>