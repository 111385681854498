<template>
  <div
    :class="`kt-grid kt-grid--hor kt-grid--root page-${$route.name} ${
      $route.meta !== undefined &&
      $route.meta !== null &&
      $route.meta.eclass !== undefined &&
      $route.meta.eclass !== null
        ? `eclass-${$route.meta.eclass}`
        : ''
    }`"
  >
    <KTHeaderMobile></KTHeaderMobile>
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
      :text="loaderText"
    ></Loader>
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
      v-if="pageReady"
    >
      <KTAside v-if="asideEnabled"></KTAside>
      <div
        class="
          kt-grid__item kt-grid__item--fluid
          kt-grid kt-grid--hor
          kt-wrapper
        "
        id="kt_wrapper"
      >
        <KTHeader></KTHeader>
        <div
          class="
            kt-content
            kt-grid__item kt-grid__item--fluid
            kt-grid kt-grid--hor
          "
          id="kt_content"
        >
          <KTSubheader
            v-if="subheaderDisplay"
            :showAccountWarning="accountDueWarning"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          ></KTSubheader>
          <div
            class="kt-container kt-grid__item kt-grid__item--fluid"
            v-bind:class="{ 'kt-container--fluid': contentFluid }"
          >
            <!-- <v-alert
              type="warning"
              class="bg-danger cl-dark"
              dismissible
              v-model="accountDueWarning"
              v-if="accountDueWarning"
            >
              Your account has an outstanding balance
              <! -- <div v-if="$route.name !== 'my-account-invoices'">
                <v-btn color="dark" @click="$router.push('/MyAccount/Invoices')"
                  >Click here to view unpaid invoices.</v-btn
                >
              </div> - ->
            </v-alert> -->
            <transition name="fade-in-up">
              <router-view></router-view>
            </transition>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
    <!-- <KTQuickPanel></KTQuickPanel> -->
    <KTScrollTop></KTScrollTop>
    <VOffline ref="onlineOffline">
      <div style="width: 100%; text-align: center">
        You appear to be offline. Some services will not function until you are
        re-connected.
      </div>
    </VOffline>
    <Changelog />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/views/theme/aside/Aside.vue";
import KTHeader from "@/views/theme/header/Header.vue";
import KTHeaderMobile from "@/views/theme/header/HeaderMobile.vue";
import KTFooter from "@/views/theme/footer/Footer.vue";
//import HtmlClass from "@/common/htmlclass.service";
import KTSubheader from "@/views/theme/subheader/Subheader.vue";
//import KTStickyToolbar from "@/views/partials/layout/StickyToolbar.vue";
//import KTQuickPanel from "@/views/partials/layout/QuickPanel.vue";
import KTScrollTop from "@/views/partials/layout/ScrollTop";
import Loader from "@/views/partials/content/Loader.vue";
import VOffline from "@/views/theme/VOffline.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
  RESET_CLASSNAMES,
} from "@/store/htmlclass.module.js";

import Changelog from '../partials/content/Changelog.vue';

export default {
  name: "Base",
  components: {
    Changelog,
    VOffline,
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    //KTStickyToolbar,
    //KTQuickPanel,
    KTScrollTop,
    Loader,
  },
  created() {
    let self = this;
    self.$eventBus.on("cache-my-account:billing-invoices", (invoices) => {
      let hasUnpaidInvoices = false;
      for (let invoice of invoices) {
        if (invoice.status === "unpaid") {
          hasUnpaidInvoices = true;
          break;
        }
      }
      self.$data.accountDueWarning = hasUnpaidInvoices;
      //console.log("client info updated");
      /*if (!self.$tools.isNullOrUndefined(cli.accountOutstanding))
        self.$data.accountDueWarning = cli.accountOutstanding > 0;*/

      /*setTimeout(() => {
        // Remove page loader after some time
        self.$data.pageReady = true;
        self.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
      }, 1000);*/
    });
    /*self.$eventBus.on("client-changed", clientId => {
      
    });*/
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");

    // initialize html element classes
    //HtmlClass.init();
    this.$store.dispatch(RESET_CLASSNAMES);

    //Vue.prototype.$eventBus.emit( `page-loader`, 'Fetching client data' );
    //Vue.prototype.$eventBus.emit( `client-information-updated`, resp.data );
  },
  mounted() {
    let self = this;
    //this.$eventBus.on("crm-invoice-overdue", msg => {});
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push("/Auth/Load");
    }

    // Simulate the delay page loading
    /*setTimeout(() => {
      // Remove page loader after some time

      let refClient = window.sessionStorage.getItem("client");
      if (!self.isAuthenticated || !self.$tools.isNullOrUndefined(refClient)) {
        self.$data.pageReady = true;
        self.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
      }

      if (!self.$tools.isNullOrUndefined(refClient)) {
        let client = JSON.parse(refClient);
        if (self.$tools.isNullOrUndefined(client.accountOutstanding)) return;
        self.$data.accountDueWarning = client.accountOutstanding > 0;
      }
    }, 2000);*/

    self.$data.pageReady = true;
    self.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");

    /*this.$eventBus.on("ws-log", (msg) => {
      self.$log.log(`DD:${msg}`);
    });*/
  },
  watch: {
    accountDueWarning() {
      this.$log.log(
        "Account invoice unpaid warning " +
          (this.accountDueWarning ? "shown" : "dismissed")
      );
    },
  },
  data() {
    return {
      accountDueWarning: false,
      pageReady: false,
    };
  },
  beforeDestroy() {
    //this.$eventBus.off("");
  },
  methods: {},
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    loaderText() {
      return this.$root.loaderText;
    },

    /**
     * Check if the page laoder is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !!this.layoutConfig("loader.enabled");
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutConfig("toolbar.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>

<style scoped>
.kt-content::before {
  content: "";
  width: 100%;
  height: 50px;
  background: #343335;
  position: fixed;
  margin-top: -101px;
  z-index: 15;
}
body.kt-brand-light .kt-content::before {
  background: #ebebeb;
}
</style>