<template>
  <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem
        v-if="menu.hidden != true"
        v-bind:menu="menu"
        :key="i"
      ></KTMenuItem>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import KTMenuItem from "@/views/theme/header/MenuItem.vue";
import menuConfig from "@/common/config/menu.config.json";

export default {
  name: "KTHeaderMenu",
  components: {
    KTMenuItem
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    menuItems: () => {
      return menuConfig.header.items;
    }
  }
};
</script>
