<template>
  <div>
    <div
      class="kt-head kt-head--skin-dark kt-head--fit-x kt-head--fit-b"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h3 class="kt-head__title">Notifications</h3>
      <div
        v-if="showRegisterPush"
        style="text-align: center; padding: 20px; padding-bottom: 10px"
      >
        <button
          @click="registerSWPush()"
          type="button"
          class="btn btn-success btn-sm"
        >
          Enable Push Notifications
        </button>
      </div>
      <ul
        class="
          nav
          nav-tabs
          nav-tabs-line
          nav-tabs-bold
          nav-tabs-line-3x
          nav-tabs-line-success
          kt-notification-item-padding-x
        "
        role="tablist"
      >
        <li class="nav-item">
          <v-badge
            :transition="
              notificationCount.alert > 0
                ? 'scroll-y-reverse-transition'
                : 'scroll-y-transition'
            "
            bordered
            style="fill: var(--danger)"
            :value="notificationCount.alert > 0"
            :content="
              notificationCount.alert >= 10 ? '+' : notificationCount.alert
            "
            overlap
          >
            <a
              v-on:click="setActiveTab"
              data-tab="0"
              class="nav-link active"
              data-toggle="tab"
              href="#"
              role="tab"
              >Alerts</a
            >
          </v-badge>
        </li>
        <li class="nav-item">
          <v-badge
            :transition="
              notificationCount.task > 0
                ? 'scroll-y-reverse-transition'
                : 'scroll-y-transition'
            "
            bordered
            style="fill: var(--danger)"
            :value="notificationCount.task > 0"
            :content="
              notificationCount.task >= 10 ? '+' : notificationCount.task
            "
            overlap
          >
            <a
              v-on:click="setActiveTab"
              data-tab="1"
              class="nav-link"
              data-toggle="tab"
              href="#"
              role="tab"
              >Tasks</a
            >
          </v-badge>
        </li>
        <li class="nav-item">
          <v-badge bordered style="fill: var(--danger)" :value="false" overlap>
            <a
              v-on:click="setActiveTab"
              data-tab="2"
              class="nav-link"
              data-toggle="tab"
              href="#"
              role="tab"
              >Events/Logs</a
            >
          </v-badge>
        </li>
      </ul>
    </div>

    <b-tabs class="kt-hide-tabs" v-model="tabIndex">
      <b-tab active>
        <perfect-scrollbar
          class="kt-notification kt-margin-t-10 kt-margin-b-10"
          style="max-height: 40vh; position: relative"
        >
          <KTDropdownNotificationItem
            v-if="notifications.alert.length == 0"
            :notification="{
              noAction: true,
              icon: 'flaticon2-check-mark',
              color: 'success',
              title: 'No Alerts'
            }"
          />
          <KTDropdownNotificationItem
            v-for="(notification, i) of notifications.alert"
            v-bind:key="`${notification.time}.${i}`"
            :notification="notification"
          />
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <perfect-scrollbar
          class="kt-notification kt-margin-t-10 kt-margin-b-10"
          style="max-height: 40vh; position: relative"
        >
          <KTDropdownNotificationItem
            v-if="notifications.task.length == 0"
            :notification="{
              noAction: true,
              icon: 'flaticon2-check-mark',
              color: 'success',
              title: 'No Tasks'
            }"
          />
          <KTDropdownNotificationItem
            v-for="(notification, i) of notifications.task"
            v-bind:key="`${notification.time}.${i}`"
            :notification="notification"
          />
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <perfect-scrollbar
          class="kt-notification kt-margin-t-10 kt-margin-b-10"
          style="max-height: 40vh; position: relative"
        >
          <KTDropdownNotificationItem
            v-if="notifications.event.length == 0"
            :notification="{
              noAction: true,
              icon: 'flaticon2-check-mark',
              color: 'success',
              title: 'No Events'
            }"
          />
          <KTDropdownNotificationItem
            v-for="(notification, i) of notifications.event"
            v-bind:key="`${notification.time}.${i}`"
            :notification="notification"
          />
        </perfect-scrollbar>
        <div
          v-if="notifications.event.length > 0"
          style="
            cursor: pointer;
            text-align: center;
            width: 100%;
            padding: 10px;
            background: var(--light);
            color: var(--dark);
          "
          @click="$emit('clearEventLogs')"
        >
          CLEAR LOGS
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.kt-hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import DropdownNotificationItem from "./DropdownNotificationItem";
import { mapGetters } from "vuex";

export default {
  name: "KTDropdownNotification",
  props: ["notifications", "notificationCount"],
  components: {
    KTDropdownNotificationItem: DropdownNotificationItem
  },
  data() {
    return {
      tabIndex: 0,
      showRegisterPush: false
    };
  },
  mounted() {
    const self = this;
    window.bsb.push.isSubscribed().then(x => {
      self.$data.showRegisterPush = x === false;
    });
  },
  methods: {
    registerSWPush() {
      this.$data.showRegisterPush = false;
      const self = this;
      window.bsb.push
        .subscribe(self.token)
        .then(() => {
          self.$log.info("SW Push Notifications");
        })
        .catch(self.$log.error);
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["token"]),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    }
  }
};
</script>
