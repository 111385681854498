<template>
  <li aria-haspopup="true" :class="{
    'kt-menu__item': true,
    'kt-menu__item-comingsoon': menu.comingsoon === true,
    'kt-menu__item--submenu': menu.submenu,
    'kt-menu__item--open': activeMenu(menu)
  }" :data-ktmenu-submenu-toggle="submenuToggle(menu)">
    <KTMenuItemText v-bind:menu="menu"></KTMenuItemText>
    <KTMenuSubmenu v-if="menu.submenu" v-bind:submenu="menu.submenu" v-bind:parentMenu="menu"></KTMenuSubmenu>
  </li>
</template>

<script>
import KTMenuItemText from "@/views/theme/aside/MenuItemText.vue";
import KTMenuSubmenu from "@/views/theme/aside/MenuSubmenu.vue";

export default {
  name: "KTMenuItem",
  components: {
    KTMenuItemText,
    KTMenuSubmenu
  },
  props: {
    menu: Object
  },
  methods: {
    activeMenu(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(conf => {
        if (conf.submenu) {
          return this.activeMenu(conf.submenu);
        }
        if (conf.page) {
          // current path starts with this path string
          if (
            conf.dynamicPath &&
            this.$route.path.indexOf(`/${ conf.page }`) === 0
          )
            return true;
          if (
            !this.$tools.isNullOrUndefined(this.$route.meta) &&
            !this.$tools.isNullOrUndefined(
              this.$route.meta.menuAssistantPath
            ) &&
            this.$route.meta.menuAssistantPath.indexOf(`/${ conf.page }`) === 0
          )
            return true;
          return this.$route.path === `/${ conf.page }`;
        }
      });
    },
    /**
     * Get submenu toggle type
     * @param menu
     * @returns {string|*}
     */
    submenuToggle(menu) {
      if (menu.hasOwnProperty("toggle")) {
        return menu.toggle;
      }
      return "hover";
    }
  }
};
</script>

<style>
body.kt-aside--minimize .kt-aside-menu-wrapper .kt-menu__item.kt-menu__item-comingsoon {
  display: none;
}
</style>