<template>
  <ul class="kt-menu__nav" style="padding: 0;">
    <div style="padding-left: 15px;padding-right: 15px;" v-if="menuItems.length > 10">
      <v-text-field name="Search" label="Search" v-model="search" />
    </div>
    <template v-for="(menu, i) in filteredMenuItems">
      <template v-if="showUp && permissionOkay(menu) && serviceOkay(menu)">
        <KTMenuItem v-bind:key="`${ i }-${ menu.section || menu.title }`" v-if="menu.hidden != true && !menu.section"
          :menu="menu"></KTMenuItem>
        <KTMenuSection v-bind:key="`${ i }-${ menu.section || menu.title }`" v-if="menu.hidden != true && menu.section"
          :menu="menu"></KTMenuSection>
      </template>
    </template>
  </ul>
  <!-- <ul class="kt-menu__nav" v-else>
  </ul> -->
</template>

<script>
import KTMenuItem from "@/views/theme/aside/MenuItem.vue";
import KTMenuSection from "@/views/theme/aside/MenuSection.vue";
import menuConfig from "@/common/config/menu.config.json";
import { mapGetters } from "vuex";

export default {
  name: "KTAsideMenu",
  components: {
    KTMenuItem,
    KTMenuSection
  },
  methods: {
    permissionOkay(menuItem) {
      return this.$root.permissionOkay(menuItem);
    },
    serviceOkay(menuItem) {
      return this.$root.serviceOkay(menuItem);
    },
    reRender() {
      let self = this;
      self.$data.showUp = false;
      self.$nextTick(() => {
        self.$data.showUp = true;
      });
    },
    okayPageWatcher(to) {
      if (to.fullPath === "/App/Dashboard") {
        //return;
        //console.log(vm.menuItems)
        if (
          (this.$root.layoutConfig().hidePages || []).indexOf("NS:Dashboard") >=
          0
        ) {
          for (let menuItem of this.menuItems) {
            if (!menuItem.page) {
              continue;
            }
            // pass the link prepend with slash
            this.$router.push(`/${ menuItem.page }`);
            return;
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig", "clientId"]),

    filteredMenuItems() {
      const self = this;
      if (this.search === '') return this.menuItems;

      let requiredSections = [];
      const sAsLower = this.search.toLowerCase();
      let filteredItems = this.menuItems.filter(x => {
        if (!self.$tools.isNullOrUndefined(x.section)) return true;
        if (`${ x.title }`.toLowerCase().indexOf(sAsLower) >= 0) {
          if (requiredSections.indexOf(x.for) < 0) requiredSections.push(x.for);
          return true;
        }
        return false;
      });
      filteredItems = filteredItems.filter(x => {
        if (!self.$tools.isNullOrUndefined(x.for)) return true;
        if (requiredSections.indexOf(x.section) >= 0) return true;
        return false;
      });
      return filteredItems;
    },
    menuItems() {
      //if (!this.showUp) return [];
      let workableMenuItems = JSON.parse(
        JSON.stringify(menuConfig.aside.items)
      ).filter(x => x.hidden !== true);
      let layoutConfig = this.layoutConfig();
      layoutConfig.showMenus = layoutConfig.showMenus || [];
      layoutConfig.hideMenus = layoutConfig.hideMenus || [];
      layoutConfig.hidePages = layoutConfig.hidePages || [];
      if (
        layoutConfig.customMenu !== undefined &&
        layoutConfig.customMenu !== null &&
        layoutConfig.customMenu.aside !== undefined &&
        layoutConfig.customMenu.aside !== null
      ) {
        for (let menuKey of Object.keys(layoutConfig.customMenu.aside)) {
          if (layoutConfig.customMenu.aside[menuKey].hidden === true) {
            continue;
          }
          layoutConfig.customMenu.aside[menuKey].page = `Custom/${ menuKey }`;
          if (
            layoutConfig.customMenu.aside[menuKey].inSection !== undefined &&
            layoutConfig.customMenu.aside[menuKey].inSection !== null
          ) {
            let foundIndex = -1;
            let foundEndIndex = -1;
            for (let i = 0; i < workableMenuItems.length; i++) {
              if (
                workableMenuItems[i].section ===
                layoutConfig.customMenu.aside[menuKey].inSection
              ) {
                foundIndex = i;
                break;
              }
            }
            if (foundIndex >= 0) {
              if (
                layoutConfig.hideMenus.indexOf(
                  layoutConfig.customMenu.aside[menuKey].inSection
                ) >= 0
              )
                continue;
              foundEndIndex = foundIndex;
              for (let i = foundIndex + 1; i < workableMenuItems.length; i++) {
                if (workableMenuItems[i].section !== undefined) {
                  foundEndIndex = i;
                  break;
                }
              }
              //console.log(foundEndIndex);
              workableMenuItems.splice(
                foundEndIndex - 1,
                0,
                layoutConfig.customMenu.aside[menuKey]
              );
            } else {
              workableMenuItems.push(layoutConfig.customMenu.aside[menuKey]);
            }
          } else {
            workableMenuItems.push(layoutConfig.customMenu.aside[menuKey]);
          }
        }
      }
      //console.log(layoutConfig.showMenus)
      if (layoutConfig.hideMenus.indexOf("My Account") < 0)
        layoutConfig.showMenus.push("My Account");

      /*for (let i = 0; i < workableMenuItems.length; i++) {
        if (
          workableMenuItems[i].section !== undefined &&
          layoutConfig.showMenus.indexOf(workableMenuItems[i].section) < 0
        ) {
          workableMenuItems.splice(i, 1);
          i--;
          continue;
        }
        if (
          workableMenuItems[i].for !== undefined &&
          layoutConfig.showMenus.indexOf(workableMenuItems[i].for) < 0
        ) {
          workableMenuItems.splice(i, 1);
          i--;
          continue;
        }
        if (
          layoutConfig.hidePages.indexOf(
            (workableMenuItems[i].section || "NS") +
              ":" +
              (workableMenuItems[i].title || "NT")
          ) >= 0
        ) {
          workableMenuItems.splice(i, 1);
          i--;
          continue;
        }
      }*/
      /*if (this.clientId === null)
        return [
          {
            hidden: false,
            icon: "flaticon-squares",
            page: "MyAccount/Clients",
            root: true,
            title: "Clients",
          },
        ];*/
      return workableMenuItems.filter(x => {
        if (
          x.section !== undefined &&
          layoutConfig.showMenus.indexOf(x.section) < 0
        ) {
          return false;
        }
        if (x.for !== undefined && layoutConfig.showMenus.indexOf(x.for) < 0) {
          return false;
        }
        if (
          layoutConfig.hidePages.indexOf(
            (x.section || x.for || "NS") + ":" + (x.title || "NT")
          ) >= 0
        ) {
          return false;
        }
        return true;
      }).concat([{
        section: ' '
      },
     {
      for:  ' ',
      title: 'Feature/Bug Request',
      special: 1,
      url: 'https://forms-za.betterportal.cloud/e1b8a4ae-cc85-4163-8a6f-5bbad3b9939f'
     }]);
    }
  },
  data() {
    return {
      showUp: true,
      search: '',
    };
  },
  beforeDestroy() {
    this.$eventBus.off("core-rerender-aside");
  },
  watch: {
    $route(to) {
      this.search = '';
      this.okayPageWatcher(to);
    }
  },
  mounted() {
    let self = this;
    self.$eventBus.on("core-rerender-aside", () => {
      self.reRender();
    });
    self.okayPageWatcher(self.$route);
  }
};
</script>
