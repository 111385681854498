<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <div style="margin-right: 10px;" class="d-lg-none">
      <ServicesSelector></ServicesSelector>
    </div>
    <!--begin: Search -->
    <div v-if="config.header.topbar.search.display !== false"
      class="kt-header__topbar-item kt-header__topbar-item--search" id="kt_quick_search_toggle">
      <div class="kt-header__topbar-wrapper" data-toggle="dropdown">
        <span class="kt-header__topbar-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
        </span>
      </div>
      <div class="
          dropdown-menu dropdown-menu-fit dropdown-menu-lg dropdown-menu-right
        " v-on:click.stop>
        <KTSearchDefault></KTSearchDefault>
      </div>
    </div>
    <!--end: Search -->

    <!--begin: Notifications -->
    <div v-if="
      config.header.topbar.notifications.display !== false &&
      !$tools.isNullOrUndefined(notifications)
    " class="kt-header__topbar-item" id="kt_notification_toggle">
      <div class="kt-header__topbar-wrapper" data-toggle="dropdown" style="margin-top: 10px">
        <v-badge :transition="
          notificationCount.total > 0
            ? 'scroll-y-reverse-transition'
            : 'scroll-y-transition'
        " bordered style="fill: var(--danger)" :value="notificationCount.total > 0" :content="
  notificationCount.total >= 10 ? '+' : notificationCount.total
" overlap>
          <span :class="
            `kt-header__topbar-icon ${ notificationCount.total > 0 || notificationPopper
              ? 'kt-pulse kt-pulse--brand'
              : ''
            }`
          ">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M2.56 10.682l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121zm12 0l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121z" fill="#000" opacity=".3"/><path d="M8.56 16.682l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121zm0-12l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0L8.561 6.803a1.5 1.5 0 010-2.121z" fill="#000"/></g></svg>
            <span class="kt-pulse__ring"></span>
          </span>
        </v-badge>
      </div>
      <div class="
          dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right
        " v-on:click.stop>
        <form>
          <KTDropdownNotification @clearEventLogs="clearEventLogs()" :notifications="notifications"
            :notificationCount="notificationCount"></KTDropdownNotification>
        </form>
      </div>
    </div>
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <div v-if="config.header.topbar['quick-actions'].display !== false" class="kt-header__topbar-item">
      <div class="kt-header__topbar-wrapper" id="kt_quick_action_toggle" data-toggle="dropdown">
        <span class="kt-header__topbar-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" opacity=".3" x="13" y="4" width="3" height="16" rx="1.5"/><rect fill="#000" x="8" y="9" width="3" height="11" rx="1.5"/><rect fill="#000" x="18" y="11" width="3" height="9" rx="1.5"/><rect fill="#000" x="3" y="13" width="3" height="7" rx="1.5"/></g></svg>
        </span>
      </div>
      <div class="
          dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right
        " v-on:click.stop>
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </div>
    </div>
    <!--end: Quick Actions -->

    <!--begin: My Cart -->
    <div v-if="config.header.topbar['my-cart'].display !== false" class="kt-header__topbar-item">
      <div class="kt-header__topbar-wrapper" id="kt_my_cart_toggle" data-toggle="dropdown">
        <span class="kt-header__topbar-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12 4.562L7.768 9.64a1 1 0 11-1.536-1.28l5-6a1 1 0 011.536 0l5 6a1 1 0 11-1.536 1.28L12 4.562z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M3.5 9h17a1 1 0 01.923 1.385l-3.654 8.769A3 3 0 0115 21H9a3 3 0 01-2.77-1.846l-3.653-8.77A1 1 0 013.5 9zm8.5 8a2 2 0 100-4 2 2 0 000 4z" fill="#000"/></g></svg>
        </span>
      </div>
      <div class="
          dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right
        " v-on:click.stop>
        <KTDropdownMyCart></KTDropdownMyCart>
      </div>
    </div>
    <!--end: My Cart -->

    <!--begin: Quick panel toggler -->
    <div v-if="config.header.topbar['quick-panel'].display !== false"
      class="kt-header__topbar-item kt-header__topbar-item--quick-panel" v-b-tooltip.hover.bottom="'Quick panel'">
      <span class="kt-header__topbar-icon" id="kt_quick_panel_toggler_btn">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" x="4" y="4" width="7" height="7" rx="1.5"/><path d="M5.5 13h4a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-4A1.5 1.5 0 015.5 13zm9-9h4A1.5 1.5 0 0120 5.5v4a1.5 1.5 0 01-1.5 1.5h-4A1.5 1.5 0 0113 9.5v-4A1.5 1.5 0 0114.5 4zm0 9h4a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-4a1.5 1.5 0 011.5-1.5z" fill="#000" opacity=".3"/></g></svg>
      </span>
    </div>
    <!--end: Quick panel toggler -->

    <!--begin: Language bar -->
    <div v-if="config.header.topbar.languages.display !== false"
      class="kt-header__topbar-item kt-header__topbar-item--langs">
      <div class="kt-header__topbar-wrapper" id="kt_language_toggle" data-toggle="dropdown">
        <span class="kt-header__topbar-icon">
          <img :src="languageFlag || getLanguageFlag" alt />
        </span>
      </div>
      <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right" v-on:click.stop>
        <KTDropdownLanguage v-on:language-changed="onLanguageChanged"></KTDropdownLanguage>
      </div>
    </div>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div class="kt-header__topbar-wrapper" id="kt_user_toggle" data-toggle="dropdown">
        <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-welcome future-kt-hidden-mobile">Hi,</span>
          <span class="kt-header__topbar-username future-kt-hidden-mobile">{{
              currentUser ? currentUser.name : ""
          }}</span>
          <img xclass="kt-hidden" alt="Pic" :src="userIcon" />
          <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
          <span class="
              kt-hidden
              kt-badge
              kt-badge--username
              kt-badge--unified-success
              kt-badge--lg
              kt-badge--rounded
              kt-badge--bold
            ">S</span>
        </div>
      </div>
      <div class="
          dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right
        " v-on:click.stop style="border-radius: 15px; margin-top: 15px;">
        <KTDropdownUser></KTDropdownUser>
      </div>
    </div>
    <!--end: User Bar -->
    <v-snackbar-queue :timeout="30000" :items="snacks" @remove="removeSnack" bottom right></v-snackbar-queue>
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTSearchDefault from "@/views/theme/topbar/SearchDefault.vue";
import KTDropdownNotification from "@/views/theme/topbar/DropdownNotification.vue";
import KTDropdownQuickAction from "@/views/theme/topbar/DropdownQuickAction.vue";
import KTDropdownMyCart from "@/views/theme/topbar/DropdownMyCart.vue";
import KTDropdownLanguage from "@/views/theme/topbar/DropdownLanguage.vue";
import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";
import ServicesSelector from '../subheader/ServicesSelector.vue';
import i18nService from "@/common/i18n.service.js";
import { mapGetters } from "vuex";
//import ApiService from "@/common/api.service";
//import JWTService from "@/common/jwt.service";
import { Gravatar } from "@/common/gravatar.js";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      notificationTypes: {
        alert: {
          title: "Alerts",
          addSnack: true
        },
        event: {
          title: "Events",
          includeInCount: false,
          autoRead: 60000,
          autoAccept: 1800000
        },
        task: {
          title: "Tasks"
        }
      },
      notificationPopperC: 0,
      notifications: null,
      snacks: [],
      notifications2: []
    };
  },
  components: {
    KTSearchDefault,
    KTDropdownNotification,
    KTDropdownQuickAction,
    KTDropdownMyCart,
    KTDropdownLanguage,
    KTDropdownUser,
    ServicesSelector
  },
  methods: {
    clearEventLogs() {
      let notifObj = window.localStorage.getItem("notifications");
      if (this.$tools.isNullOrUndefined(notifObj)) return;
      notifObj = JSON.parse(notifObj);
      notifObj.events.splice(0);
      window.localStorage.setItem("notifications", JSON.stringify(notifObj));
      this.$data.notifications = notifObj;
    },
    removeSnack(id) {
      const index = this.snacks.findIndex(item => item.id === id);

      if (index !== -1) {
        this.snacks.splice(index, 1);
      }
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    getNotifications() {
      let notifObj = window.localStorage.getItem("notifications");
      if (this.$tools.isNullOrUndefined(notifObj)) notifObj = {};
      else notifObj = JSON.parse(notifObj);

      for (let notifType of Object.keys(this.notificationTypes)) {
        if (this.$tools.isNullOrUndefined(notifObj[notifType]))
          notifObj[notifType] = [];
      }

      let refClient = window.sessionStorage.getItem("client");
      if (!this.$tools.isNullOrUndefined(refClient)) {
        let exists = -1;
        let accountDueWarning = false;
        let client = JSON.parse(refClient);
        if (!this.$tools.isNullOrUndefined(client.accountOutstanding)) {
          accountDueWarning = client.accountOutstanding > 0;
        }
        for (let i = 0; i < notifObj.alerts.length; i++) {
          if (notifObj.alerts[i].key === "oustanding-invoices") {
            exists = i;
            break;
          }
        }
        if (accountDueWarning && exists < 0) {
          notifObj.alerts.unshift({
            key: "oustanding-invoices",
            remove: false,
            value: {
              title: "You have unpaid invoices",
              color: "danger",
              icon: "flaticon2-warning",
              link: "/MyAccount/Invoices"
            }
          });
        } else if (!accountDueWarning && exists >= 0) {
          notifObj.alerts.splice(exists, 1);
        }
      }

      return notifObj;
    },
    cleanupNotifications() {
      let self = this;
      let notifObj = self.getNotifications();

      let now = new Date().getTime();
      for (let notifType of Object.keys(this.notificationTypes)) {
        if (
          self.$tools.isNullOrUndefined(
            this.notificationTypes[notifType].autoRead
          )
        )
          continue;

        for (let i = 0; i < notifObj[notifType].length; i++) {
          if (notifObj[notifType][i].read == true) continue;

          if (
            now - notifObj[notifType][i].time >
            this.notificationTypes[notifType].autoRead
          ) {
            notifObj[notifType][i].read = true;
          }
        }
      }

      for (let notifType of Object.keys(this.notificationTypes)) {
        if (
          self.$tools.isNullOrUndefined(
            this.notificationTypes[notifType].autoAccept
          )
        )
          continue;

        for (let i = 0; i < notifObj[notifType].length; i++) {
          if (
            now - notifObj[notifType][i].time >
            this.notificationTypes[notifType].autoAccept
          ) {
            notifObj[notifType].splice(i, 1);
            i = -1;
          }
        }
      }

      window.localStorage.setItem("notifications", JSON.stringify(notifObj));
      self.$data.notifications = notifObj;
    },
    forceUpdateNotifications() {
      //const self = this;
      // TODO: Update notifications
      /*ApiService.get(`crm/${JWTService.getActiveCrmID()}`, `notifications`)
        .then((x) => {
          self.$data.notifications2 = x.data;
          self.updateNotificationsObject();
        })
        .catch(self.$log.error);*/
    },
    updateNotificationsObject() {
      let newObj = {};

      for (let notifType of Object.keys(this.notificationTypes)) {
        newObj[notifType] = [];
      }

      for (let notif of this.notifications2) {
        newObj[notif.type] = newObj[notif.type] || [];
        newObj[notif.type].push(notif);
      }

      this.$data.notifications = newObj;
    }
  },
  mounted() {
    let self = this;
    /*setInterval(self.cleanupNotifications, 60000);
    self.cleanupNotifications();
    self.$data.notifications = self.getNotifications();*/

    self.$eventBus.on("client-information-updated", () => {
      //self.$data.notifications = self.getNotifications();
      self.forceUpdateNotifications();
    });
    self.$data.notifications = {
      alert: [],
      event: [],
      task: [],
    };

    self.$eventBus.on("ws-state", x => {
      if (x) self.forceUpdateNotifications();
    });
    self.$eventBus.on("ws-portal-notification", x => {
      //console.log(x);
      if (!x.new) {
        for (let i = 0; i < self.$data.notifications2.length; i++) {
          if (self.$data.notifications2[i].id === x.notification.id) {
            if (x.notification.status === "completed") {
              self.$data.notifications2.splice(i, 1);
              self.updateNotificationsObject();
              return;
            }
            self.$data.notifications2[i] = x.notification;
            self.updateNotificationsObject();
            return;
          }
        }
      }
      self.$data.notifications2.unshift(x.notification);
      self.updateNotificationsObject();
      self.$bvToast.toast(x.notification.message, {
        title: x.notification.title,
        variant: x.notification.typeInfo,
        solid: true
      });
    });
    setInterval(self.forceUpdateNotifications, 240000);
    self.forceUpdateNotifications();
    // self.$eventBus.on("notification", (notif) => {
    //   self.$data.notificationPopperC++;
    //   notif.time = notif.time || new Date().getTime();

    //   let notifObj = self.getNotifications();
    //   if (!self.$tools.isNullOrUndefined(notif.key)) {
    //     let set = false;
    //     let hasChange = false;
    //     let refId = -1;
    //     for (let i = 0; i < notifObj[notif.type].length; i++) {
    //       if (notifObj[notif.type][i].key === notif.key) {
    //         refId = i;
    //         if (notif.remove) {
    //           //notifObj[notif.type].splice(i, 1);
    //           notifObj[notif.type][i].read = true;
    //           hasChange = notifObj[notif.type][i].value.tite != notif.value;
    //           notifObj[notif.type][i].value = notif.value;
    //           set = true;
    //           notifObj[notif.type][i]._timer = setTimeout(() => {
    //             let notifObj2 = self.getNotifications();
    //             for (let i = 0; i < notifObj2[notif.type].length; i++) {
    //               if (notifObj2[notif.type][i].key === notif.key) {
    //                 notifObj2[notif.type].splice(i, 1);
    //                 break;
    //               }
    //             }
    //             window.localStorage.setItem(
    //               "notifications",
    //               JSON.stringify(notifObj2)
    //             );
    //             self.$data.notifications = notifObj2;
    //           }, 1000 * 60);
    //           break;
    //         } else if (
    //           !self.$tools.isNullOrUndefined(notifObj[notif.type][i]._timer)
    //         ) {
    //           clearTimeout(notifObj[notif.type][i]._timer);
    //         }
    //         notifObj[notif.type][i].read = false;
    //         hasChange = notifObj[notif.type][i].value.tite != notif.value;
    //         notifObj[notif.type][i].value = notif.value;
    //         set = true;
    //         break;
    //       }
    //     }
    //     let snackId = null;
    //     if (hasChange && self.notificationTypes[notif.type].addSnack == true) {
    //       snackId = `${new Date().getTime()}-${notif.time}`;
    //       self.$data.snacks.push({
    //         id: snackId,
    //         color: `bg-${notif.value.color}`,
    //         message: notif.value.title,
    //       });
    //     }
    //     if (!set) {
    //       if (snackId !== null) notif.snackId = snackId;
    //       notifObj[notif.type].unshift(notif);
    //     } else if (snackId !== null && refId != -1) {
    //       notifObj[notif.type][refId].snackId = snackId;
    //     }
    //   } else {
    //     if (self.notificationTypes[notif.type].addSnack == true) {
    //       let snackId = `${new Date().time()}-${notif.time}`;
    //       self.$data.snacks.push({
    //         id: snackId,
    //         color: `bg-${notif.value.color}`,
    //         message: notif.value.title,
    //       });
    //       notif.snackId = snackId;
    //     }
    //     notifObj[notif.type].unshift(notif);
    //   }
    //   for (let notifType of Object.keys(this.notificationTypes)) {
    //     if (notifObj[notifType].length <= 51) continue;
    //     notifObj[notifType].splice(50);
    //   }
    //   window.localStorage.setItem("notifications", JSON.stringify(notifObj));
    //   self.$data.notifications = notifObj;
    //   setTimeout(() => {
    //     self.$data.notificationPopperC--;
    //   }, 5000);
    // });
  },
  computed: {
    userIcon() {
      return Gravatar.getProfileImageUrl(
        this.currentUser,
        "https://content.betterportal.cloud/betterportal/icons8-user-58.png"
      );
    },
    notificationPopper() {
      return this.notificationPopperC > 0;
    },
    notificationCount() {
      let count = {
        total: 0
      };

      if (this.$tools.isNullOrUndefined(this.notifications)) return 0;

      for (let notifType of Object.keys(this.notificationTypes)) {
        count[notifType] = count[notifType] || 0;
        if (this.$tools.isNullOrUndefined(this.notifications[notifType]))
          continue;
        for (let notifObj of this.notifications[notifType]) {
          if (notifObj.status === "created") {
            count.total++;
            count[notifType]++;
          }
        }
      }

      return count;
    },

    ...mapGetters(["currentUser", "layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>
