<template>
  <div
    style="
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      border-radius: 15px;
    "
  >
    <!--begin: Head -->
    <div
      class="
        kt-user-card kt-user-card--skin-dark
        kt-notification-item-padding-x
      "
      :style="{
        backgroundColor: backgroundColour,
        'border-top-left-radius': '15px',
        'border-top-right-radius': '15px',
      }"
    >
      <div class="kt-user-card__avatar">
        <img xclass="kt-hidden" alt="Pic" :src="userIcon" />
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <span
          class="
            kt-hidden kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold
            kt-font-success
          "
          >S</span
        >
      </div>
      <div class="kt-user-card__name">
        <div>{{ currentUser.client.name.substring(0, 30) }}</div>
        <div>
          {{
            (
              (currentUser ? currentUser.name : "") +
              " " +
              (currentUser ? currentUser.surname : "")
            ).substring(0, 30)
          }}
        </div>
      </div>
      <div class="kt-hidden kt-user-card__badge">
        <span class="btn btn-success btn-sm btn-bold btn-font-md"
          >23 messages</span
        >
      </div>
    </div>
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <!-- <a
        :href="$keycloak.createAccountUrl()"
        target="blank"
        class="kt-notification__item"
      >
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3 kt-font-success"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">My Profile</div>
          <div class="kt-notification__item-time">
            Account settings and more
          </div>
        </div>
      </a> -->
      <!-- <a href="#" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-mail kt-font-warning"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            My Messages
          </div>
          <div class="kt-notification__item-time">Inbox and tasks</div>
        </div>
      </a>
      <a href="#" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-rocket-1 kt-font-danger"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            My Activities
          </div>
          <div class="kt-notification__item-time">Logs and notifications</div>
        </div>
      </a>
      <a href="#" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-hourglass kt-font-brand"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">My Tasks</div>
          <div class="kt-notification__item-time">
            latest tasks and projects
          </div>
        </div>
      </a>
      <a href="#" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-cardiogram kt-font-warning"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">Billing</div>
          <div class="kt-notification__item-time">
            billing & statements
            <span
              class="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
              >2 pending</span
            >
          </div>
        </div>
      </a>-->
      <div
        :class="`client-list ${showClientList ? 'show-list' : ''}`"
        v-if="Object.keys(currentUser.clients).length > 1"
      >
        <KTPortlet
          id="ktportsidelist"
          v-for="(clientId, index) in Object.keys(myClients)"
          v-bind:key="clientId"
          @click="selectClient(clientId)"
          :style="`width: 100%;
                margin: 0 auto;
                margin-bottom: ${
                  index === Object.keys(myClients).length - 1 ? '0' : '10px'
                };
                background: var(--light);
                cursor: pointer;`"
        >
          <template slot="body">
            <h3
              @click="changeClient(clientId)"
              style="
                padding: 0;
                margin: 0;
                display: inline-block;
                float: left;
                width: calc(100% - 25px);
                color: var(--dark);
              "
            >
              {{ currentUser.clients[clientId].name }}
            </h3>
            <div
              @click="changeClient(clientId)"
              style="
                display: inline-block;
                float: right;
                height: 25px;
                width: 25px;
              "
            >
              <v-icon style="color: var(--dark)"
                >mdi-arrow-right-bold-circle</v-icon
              >
            </div>
          </template>
        </KTPortlet>
      </div>
      <div
        class="kt-notification__custom kt-space-between"
        :style="{
          backgroundColor: backgroundColourLight,
          borderRadius: '15px',
        }"
      >
        <!-- <a
          v-if="Object.keys(currentUser.clients).length > 1"
          href="#"
          v-on:click="onSwitchClient()"
          class="btn btn-label btn-label-brand btn-sm btn-bold"
          >Switch Client/Account</a
        > -->
        <button
          v-if="Object.keys(currentUser.clients).length > 1"
          type="button"
          @click="showClientList = !showClientList"
          class="btn btn-primary"
        >
          {{ showClientList ? "Hide list" : "Switch Client/Account" }}
        </button>
        <button type="button" @click="onLogout()" class="btn btn-danger">
          Sign Out
        </button>
        <!-- <router-link
          to="/status"
          v-on:click="onLogout()"
          class="btn btn-clean btn-sm btn-bold"
          >Service Status</router-link
        > -->
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import { LOGOUT, DO_CLIENT_CHANGE } from "@/store/auth.module";
import { mapGetters } from "vuex";
import { Gravatar } from "@/common/gravatar.js";
import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  name: "KTDropdownUser",
  components: {
    KTPortlet,
  },
  data() {
    return {
      showClientList: false,
    };
  },
  watch: {
    $route() {
      this.$data.showClientList = false;
    },
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "Login" }));
    },
    onSwitchClient() {
      this.$store.dispatch(DO_CLIENT_CHANGE, null);
      this.$router.push("/Auth/Clients");
    },
    changeClient(clientId) {
      this.$store.dispatch(DO_CLIENT_CHANGE, null);
      this.$router.push("/Auth/Clients?SCID=" + clientId);
    },
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig", "clientId"]),

    myClients() {
      let cliList = {};
      for (let client of Object.keys(this.currentUser.clients)) {
        if (client !== this.clientId)
          cliList[client] = this.currentUser.clients[client];
      }
      return cliList;
    },

    backgroundColour() {
      return this.layoutConfig().colors.state.brand;
    },
    backgroundColourDark() {
      return this.layoutConfig().colors.state.dark;
    },
    backgroundColourLight() {
      return this.layoutConfig().colors.state.light;
    },
    userIcon() {
      return Gravatar.getProfileImageUrl(
        this.currentUser,
        "https://content.betterportal.cloud/betterportal/icons8-user-58.png"
      );
    },
  },
};
</script>

<style>
.client-list > div {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.client-list {
  height: 0;
  padding: 0;
  opacity: 0;
  overflow-y: auto;
  box-shadow: inset 0 14px 28px rgba(0, 0, 0, 0.25),
    inset 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.client-list.show-list {
  opacity: 1;
  height: 250px;
  padding: 10px;
}
</style>
