<template>
  <component
    :is="'div'"
    :style="`cursor: ${
      !$tools.isNullOrUndefined(notificationValue.link) ? 'pointer' : 'default'
    };`"
    @click="hovr(notificationValue)"
    :to="notificationValue.link"
    :class="`kt-notification__item ${
      notificationValue.noAction ? 'no-action' : ''
    } ${
      notification.status !== 'created' ? 'kt-notification__item--read' : ''
    }`"
  >
    <div
      class="kt-notification__item-icon"
      v-if="!$tools.isNullOrUndefined(notificationValue.icon)"
    >
      <i
        :class="`${notificationValue.icon} kt-font-${notificationValue.color}`"
      ></i>
    </div>
    <div class="kt-notification__item-details">
      <div class="kt-notification__item-title">
        {{ notificationValue.title }}
        <span
          v-if="!$tools.isNullOrUndefined(notification.time)"
          style="font-weight: 300; font-size: 0.8em"
          >(
          <Timeago :datetime="notification.time" :auto-update="30"></Timeago
          >)</span
        >
      </div>
      <div
        class="kt-notification__item-time"
        v-if="notificationValue.noAction !== true"
      >
        <span
          v-if="!$tools.isNullOrUndefined(notificationValue.message)"
          style="padding-left: 5px"
          >{{ notificationValue.message }}</span
        >
      </div>
      <!-- <button
        v-if="
          notificationValue.canAck &&
          notificationValue.ackTime == null &&
          notificationValue.status === 'created' &&
          $tools.isNullOrUndefined(notificationValue.link)
        "
        @click="ackItem(notificationValue)"
        type="button"
        class="btn btn-clean"
        style="text-align: center; margin-top: 5px"
      >
        acknowledge
      </button> -->
      <button
        v-if="
          notificationValue.canComplete &&
          notificationValue.completedTime == null &&
          notificationValue.status === 'acked'
        "
        @click="complItem(notificationValue)"
        type="button"
        class="btn btn-clean"
        style="text-align: center; margin-top: 5px"
      >
        clear
      </button>
    </div>
  </component>
</template>

<script>
import ApiService from "@/common/api.service";
import JWTService from "@/common/jwt.service";

export default {
  name: "KTDropdownNotificationItem",
  props: ["notification"],
  data() {
    return {};
  },
  methods: {
    hovr(notificationValue) {
      const self = this;
      if (!self.$tools.isNullOrUndefined(notificationValue.link))
        self.$nextTick(() => {
          self.$router.push(notificationValue.link);
        });
      if (notificationValue.status !== "created") return;
      this.ackItem(notificationValue);
    },
    ackItem(item) {
      const self = this;
      ApiService.put(
        `crm/${JWTService.getActiveCrmID()}/notifications/${item.id}/ack`
      )
        .then(() => {})
        .catch(self.$log.error);
    },
    complItem(item) {
      const self = this;
      ApiService.put(
        `crm/${JWTService.getActiveCrmID()}/notifications/${item.id}/complete`
      )
        .then(() => {})
        .catch(self.$log.error);
    },
  },
  computed: {
    notificationValue() {
      return this.notification || {};
    },
  },
};
</script>
