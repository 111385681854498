var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"kt-head kt-head--skin-dark kt-head--fit-x kt-head--fit-b",style:({ backgroundImage: `url(${_vm.backgroundImage})` })},[_c('h3',{staticClass:"kt-head__title"},[_vm._v("Notifications")]),(_vm.showRegisterPush)?_c('div',{staticStyle:{"text-align":"center","padding":"20px","padding-bottom":"10px"}},[_c('button',{staticClass:"btn btn-success btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.registerSWPush()}}},[_vm._v(" Enable Push Notifications ")])]):_vm._e(),_c('ul',{staticClass:"nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('v-badge',{staticStyle:{"fill":"var(--danger)"},attrs:{"transition":_vm.notificationCount.alert > 0
              ? 'scroll-y-reverse-transition'
              : 'scroll-y-transition',"bordered":"","value":_vm.notificationCount.alert > 0,"content":_vm.notificationCount.alert >= 10 ? '+' : _vm.notificationCount.alert,"overlap":""}},[_c('a',{staticClass:"nav-link active",attrs:{"data-tab":"0","data-toggle":"tab","href":"#","role":"tab"},on:{"click":_vm.setActiveTab}},[_vm._v("Alerts")])])],1),_c('li',{staticClass:"nav-item"},[_c('v-badge',{staticStyle:{"fill":"var(--danger)"},attrs:{"transition":_vm.notificationCount.task > 0
              ? 'scroll-y-reverse-transition'
              : 'scroll-y-transition',"bordered":"","value":_vm.notificationCount.task > 0,"content":_vm.notificationCount.task >= 10 ? '+' : _vm.notificationCount.task,"overlap":""}},[_c('a',{staticClass:"nav-link",attrs:{"data-tab":"1","data-toggle":"tab","href":"#","role":"tab"},on:{"click":_vm.setActiveTab}},[_vm._v("Tasks")])])],1),_c('li',{staticClass:"nav-item"},[_c('v-badge',{staticStyle:{"fill":"var(--danger)"},attrs:{"bordered":"","value":false,"overlap":""}},[_c('a',{staticClass:"nav-link",attrs:{"data-tab":"2","data-toggle":"tab","href":"#","role":"tab"},on:{"click":_vm.setActiveTab}},[_vm._v("Events/Logs")])])],1)])]),_c('b-tabs',{staticClass:"kt-hide-tabs",model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"active":""}},[_c('perfect-scrollbar',{staticClass:"kt-notification kt-margin-t-10 kt-margin-b-10",staticStyle:{"max-height":"40vh","position":"relative"}},[(_vm.notifications.alert.length == 0)?_c('KTDropdownNotificationItem',{attrs:{"notification":{
            noAction: true,
            icon: 'flaticon2-check-mark',
            color: 'success',
            title: 'No Alerts'
          }}}):_vm._e(),_vm._l((_vm.notifications.alert),function(notification,i){return _c('KTDropdownNotificationItem',{key:`${notification.time}.${i}`,attrs:{"notification":notification}})})],2)],1),_c('b-tab',[_c('perfect-scrollbar',{staticClass:"kt-notification kt-margin-t-10 kt-margin-b-10",staticStyle:{"max-height":"40vh","position":"relative"}},[(_vm.notifications.task.length == 0)?_c('KTDropdownNotificationItem',{attrs:{"notification":{
            noAction: true,
            icon: 'flaticon2-check-mark',
            color: 'success',
            title: 'No Tasks'
          }}}):_vm._e(),_vm._l((_vm.notifications.task),function(notification,i){return _c('KTDropdownNotificationItem',{key:`${notification.time}.${i}`,attrs:{"notification":notification}})})],2)],1),_c('b-tab',[_c('perfect-scrollbar',{staticClass:"kt-notification kt-margin-t-10 kt-margin-b-10",staticStyle:{"max-height":"40vh","position":"relative"}},[(_vm.notifications.event.length == 0)?_c('KTDropdownNotificationItem',{attrs:{"notification":{
            noAction: true,
            icon: 'flaticon2-check-mark',
            color: 'success',
            title: 'No Events'
          }}}):_vm._e(),_vm._l((_vm.notifications.event),function(notification,i){return _c('KTDropdownNotificationItem',{key:`${notification.time}.${i}`,attrs:{"notification":notification}})})],2),(_vm.notifications.event.length > 0)?_c('div',{staticStyle:{"cursor":"pointer","text-align":"center","width":"100%","padding":"10px","background":"var(--light)","color":"var(--dark)"},on:{"click":function($event){return _vm.$emit('clearEventLogs')}}},[_vm._v(" CLEAR LOGS ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }