<template>
  <li class="kt-menu__section">
    <h4 class="kt-menu__section-text">
      {{ menu.section }}
      <span
        v-if="!$tools.isNullOrUndefined(menu.meta)"
        style="margin-left: 10px;"
      >
        <v-chip
          v-if="menu.meta.component === 'v-chip'"
          v-bind="menu.meta.props"
        >
          {{ menu.meta.content }}
        </v-chip>
        <component
          v-else
          v-bind:is="menu.meta.component || 'span'"
          :class="menu.meta.class"
          :style="menu.meta.style"
        >
          {{ menu.meta.content }}
        </component>
      </span>
    </h4>
    <i class="kt-menu__section-icon flaticon-more-v2"></i>
  </li>
</template>

<script>
export default {
  name: "KTMenuSection",
  components: {},
  props: {
    menu: Object
  }
};
</script>
