var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`kt-grid kt-grid--hor kt-grid--root page-${_vm.$route.name} ${
    _vm.$route.meta !== undefined &&
    _vm.$route.meta !== null &&
    _vm.$route.meta.eclass !== undefined &&
    _vm.$route.meta.eclass !== null
      ? `eclass-${_vm.$route.meta.eclass}`
      : ''
  }`},[_c('KTHeaderMobile'),(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo,"text":_vm.loaderText}}):_vm._e(),(_vm.pageReady)?_c('div',{staticClass:"kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"},[(_vm.asideEnabled)?_c('KTAside'):_vm._e(),_c('div',{staticClass:"kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper",attrs:{"id":"kt_wrapper"}},[_c('KTHeader'),_c('div',{staticClass:"kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor",attrs:{"id":"kt_content"}},[(_vm.subheaderDisplay)?_c('KTSubheader',{attrs:{"showAccountWarning":_vm.accountDueWarning,"breadcrumbs":_vm.breadcrumbs,"title":_vm.pageTitle}}):_vm._e(),_c('div',{staticClass:"kt-container kt-grid__item kt-grid__item--fluid",class:{ 'kt-container--fluid': _vm.contentFluid }},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)],1),_c('KTFooter')],1)],1):_vm._e(),_c('KTScrollTop'),_c('VOffline',{ref:"onlineOffline"},[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_vm._v(" You appear to be offline. Some services will not function until you are re-connected. ")])]),_c('Changelog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }