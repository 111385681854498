<template>
  <div>
    <div v-if="showLog" class="change-log">
      <div class="change-log-window-background">
        <div style="padding-top: 10px; text-align: center;">
          <img alt="Logo" :src="logoTheme()" style="height: 50px;" />
        </div>
      </div>
      <div class="change-log-window kt-portlet">
        <h1>Change-log</h1>
        What changes have we made...
        <div v-for="change in changelog" v-bind:key="change.version" style="padding-top: 50px;">
          <h2>v{{change.version}}</h2>
          <div>{{change.date}}</div>
          <div v-for="featureChange of change.changes" v-bind:key="featureChange.feature" style="margin-left: 10px;">
            <h3>{{featureChange.feature}}</h3>
            <div v-for="typeFeature of featureChange.changes" v-bind:key="typeFeature.type">
              <v-chip :class="typeFeature.type.toLowerCase()">{{typeFeature.type}}</v-chip>
              <ul style="margin-left: 30px;">
                <li v-for="listedChange of typeFeature.changes" v-bind:key="listedChange">{{listedChange}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="change-log-button">
        <b-button @click="closeChangelog()" pill variant="primary text-white">CLOSE
        </b-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.change-log-button {
  position: fixed;
  right: 0px;
  left: 0px;
  bottom: 10px;
  margin: 0 auto;
  text-align: center;
  z-index: 999;
}

.change-log-window {
  position: fixed;
  top: 70px;
  right: 10px;
  left: 10px;
  bottom: 30px;
  margin: 0 auto;
  max-width: 600px;
  z-index: 999;
  text-align: left;
  padding: 20px;
  background-color: var(--light);
  overflow-y: auto;
}

.change-log-window-background {
  position: fixed;
  content: "";
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 998;
  background-color: #ebebeb;
}

.change-log .v-chip.added {
  background: #3778FF !important;
  color: #FFFFFF !important;
}

.change-log .v-chip.fixed {
  background: #F54773 !important;
  color: #FFFFFF !important;
}

.change-log .v-chip.changed {
  background: #3AAA55 !important;
  color: #FFFFFF !important;
}

.change-log .v-chip.depricated {
  background: #343434 !important;
  color: #FFFFFF !important;
}

.change-log .v-chip.removed {
  background: #3bbfe4 !important;
  color: #FFFFFF !important;
}

.change-log .v-chip.security {
  background: #F53535 !important;
  color: #FFFFFF !important;
}

.change-log .v-chip.unreleased {
  background: #A037FF !important;
  color: #FFFFFF !important;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "logoTheme"]),
  },
  methods: {
    closeChangelog() {
      this.showLog = false;
      window.localStorage.setItem('lastChangelog', this.latestVersion);
      if (this.latestVersion != this.$appversion) {
        window.location.reload(true);
      }
    }
  },
  data() {
    return {
      showLog: false,
      changelog: null,
      latestVersion: null,
    };
  },
  mounted() {
    let requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    const self = this;
    fetch("https://docs.betterportal.cloud/_changelog.json?dtk=" + (new Date()).getTime(), requestOptions)
      .then(response => response.json())
      .then(result => {
        let latestVersion = '1.0.0';
        let changelog = [];

        for (let change of result) {
          latestVersion = change.version;
          changelog.unshift(change);
        }

        self.changelog = changelog;
        self.latestVersion = latestVersion;
        let knownLastSeenVersion = window.localStorage.getItem('lastChangelog') || '';
        if (knownLastSeenVersion !== latestVersion) {
          self.showLog = true;
        }
      })
      .catch(error => console.log('error', error));
  },
};
</script>