var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"box-shadow":"0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)","border-radius":"15px"}},[_c('div',{staticClass:"kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x",style:({
      backgroundColor: _vm.backgroundColour,
      'border-top-left-radius': '15px',
      'border-top-right-radius': '15px',
    })},[_c('div',{staticClass:"kt-user-card__avatar"},[_c('img',{attrs:{"xclass":"kt-hidden","alt":"Pic","src":_vm.userIcon}}),_c('span',{staticClass:"kt-hidden kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"},[_vm._v("S")])]),_c('div',{staticClass:"kt-user-card__name"},[_c('div',[_vm._v(_vm._s(_vm.currentUser.client.name.substring(0, 30)))]),_c('div',[_vm._v(" "+_vm._s(( (_vm.currentUser ? _vm.currentUser.name : "") + " " + (_vm.currentUser ? _vm.currentUser.surname : "") ).substring(0, 30))+" ")])]),_vm._m(0)]),_c('div',{staticClass:"kt-notification"},[(Object.keys(_vm.currentUser.clients).length > 1)?_c('div',{class:`client-list ${_vm.showClientList ? 'show-list' : ''}`},_vm._l((Object.keys(_vm.myClients)),function(clientId,index){return _c('KTPortlet',{key:clientId,style:(`width: 100%;
              margin: 0 auto;
              margin-bottom: ${
                index === Object.keys(_vm.myClients).length - 1 ? '0' : '10px'
              };
              background: var(--light);
              cursor: pointer;`),attrs:{"id":"ktportsidelist"},on:{"click":function($event){return _vm.selectClient(clientId)}}},[_c('template',{slot:"body"},[_c('h3',{staticStyle:{"padding":"0","margin":"0","display":"inline-block","float":"left","width":"calc(100% - 25px)","color":"var(--dark)"},on:{"click":function($event){return _vm.changeClient(clientId)}}},[_vm._v(" "+_vm._s(_vm.currentUser.clients[clientId].name)+" ")]),_c('div',{staticStyle:{"display":"inline-block","float":"right","height":"25px","width":"25px"},on:{"click":function($event){return _vm.changeClient(clientId)}}},[_c('v-icon',{staticStyle:{"color":"var(--dark)"}},[_vm._v("mdi-arrow-right-bold-circle")])],1)])],2)}),1):_vm._e(),_c('div',{staticClass:"kt-notification__custom kt-space-between",style:({
        backgroundColor: _vm.backgroundColourLight,
        borderRadius: '15px',
      })},[(Object.keys(_vm.currentUser.clients).length > 1)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){_vm.showClientList = !_vm.showClientList}}},[_vm._v(" "+_vm._s(_vm.showClientList ? "Hide list" : "Switch Client/Account")+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.onLogout()}}},[_vm._v(" Sign Out ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-hidden kt-user-card__badge"},[_c('span',{staticClass:"btn btn-success btn-sm btn-bold btn-font-md"},[_vm._v("23 messages")])])
}]

export { render, staticRenderFns }