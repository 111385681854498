var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{tag:"component",class:`kt-notification__item ${
    _vm.notificationValue.noAction ? 'no-action' : ''
  } ${
    _vm.notification.status !== 'created' ? 'kt-notification__item--read' : ''
  }`,style:(`cursor: ${
    !_vm.$tools.isNullOrUndefined(_vm.notificationValue.link) ? 'pointer' : 'default'
  };`),attrs:{"to":_vm.notificationValue.link},on:{"click":function($event){return _vm.hovr(_vm.notificationValue)}}},[(!_vm.$tools.isNullOrUndefined(_vm.notificationValue.icon))?_c('div',{staticClass:"kt-notification__item-icon"},[_c('i',{class:`${_vm.notificationValue.icon} kt-font-${_vm.notificationValue.color}`})]):_vm._e(),_c('div',{staticClass:"kt-notification__item-details"},[_c('div',{staticClass:"kt-notification__item-title"},[_vm._v(" "+_vm._s(_vm.notificationValue.title)+" "),(!_vm.$tools.isNullOrUndefined(_vm.notification.time))?_c('span',{staticStyle:{"font-weight":"300","font-size":"0.8em"}},[_vm._v("( "),_c('Timeago',{attrs:{"datetime":_vm.notification.time,"auto-update":30}}),_vm._v(")")],1):_vm._e()]),(_vm.notificationValue.noAction !== true)?_c('div',{staticClass:"kt-notification__item-time"},[(!_vm.$tools.isNullOrUndefined(_vm.notificationValue.message))?_c('span',{staticStyle:{"padding-left":"5px"}},[_vm._v(_vm._s(_vm.notificationValue.message))]):_vm._e()]):_vm._e(),(
        _vm.notificationValue.canComplete &&
        _vm.notificationValue.completedTime == null &&
        _vm.notificationValue.status === 'acked'
      )?_c('button',{staticClass:"btn btn-clean",staticStyle:{"text-align":"center","margin-top":"5px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.complItem(_vm.notificationValue)}}},[_vm._v(" clear ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }