<template>
  <b-modal hide-footer size="xl" ref="popModal" :title="menu.title">
    <iframe :src="smartUrl" border="0" style="width: 100%; border: none; height: calc(100vh - 150px);"></iframe>
  </b-modal>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig", "clientId"]),

    smartUrl() {
      let newUrl = this.menu.url;
      if (newUrl.indexOf('?') > 0) {
        newUrl += '&'
      } else {
        newUrl += '?'
      }
      let elements = [
        `path=${encodeURIComponent(this.$route.path)}`,
        `serviceId=${encodeURIComponent((this.$route.params||{}).serviceId||'')}`,
        `clientId=${encodeURIComponent(this.clientId)}`,
        `userId=${encodeURIComponent(this.currentUser.userId)}`,
        `tenantId=${encodeURIComponent(this.layoutConfig().tenantId)}`,
        `appId=${encodeURIComponent(this.layoutConfig().appId)}`
      ];

      return newUrl + elements.join('&');
    }
  },
  props: {
    menu: Object
  },
  methods: {
    click() {
      this.$refs.popModal.show();
    }
  }
};
</script>